<script setup lang="ts">

import {useThrottleFn, onClickOutside, useElementHover} from "@vueuse/core";

const route = useRoute();

useHead({
	titleTemplate: (titleChunk) => {
		return titleChunk ? `${titleChunk} - EvoNap` : 'EvoNap';
	},
});
useSeoMeta({
	ogTitle: () => {
		return route.meta.title ? `EvoNap - ${route.meta.title}` : 'EvoNap';
	},
	description: 'Welcome to EvoNap!.',
	ogDescription: 'Welcome to EvoNap!',
	ogImage: '/images/logo.png',

	twitterCard: 'summary_large_image',
	twitterTitle: 'EvoNap',
	twitterImage: '/images/logo.png',
});

const isNavMenuOpen = ref(false);
const navMenuToggle = ref();
const mobileNav = ref();
onClickOutside(mobileNav, ()=>isNavMenuOpen.value = false, { ignore: [ navMenuToggle ] });
const toggleNavMenu = () => {
	isNavMenuOpen.value = !isNavMenuOpen.value;
};

const isAtTop = ref(true);
const handleScroll = () => {
	isAtTop.value = window.scrollY < 30;
};
const isHomePage = computed(() => route.path === '/');
const isJoinUsPage = computed(() => route.path === '/join-us');
const shouldNavBeFixed = computed(() => (isAtTop.value /*|| !isHomePage.value*/));

const showProducts = ref(false);
const productDropdown = ref();
const productDropdownBtn = ref();
const showProductsHover = useElementHover(productDropdownBtn, { delayLeave: 500 });
const showProductsHover2 = useElementHover(productDropdown, { delayLeave: 500 });
onClickOutside(productDropdown, ()=>showProducts.value=false);

const showProductsMobile = ref(false);
const toggleMobileProductDropdown = () => {
	showProductsMobile.value = !showProductsMobile.value;
};


const handleScrollThrottled = useThrottleFn(handleScroll, 0);

onMounted(() => {
	handleScrollThrottled();
	document.addEventListener('scroll', handleScrollThrottled);
	showProducts.value = false;
});
onUnmounted(() => {
	document.removeEventListener('scroll', handleScrollThrottled);
});

</script>

<template>
	<div data-theme="light" class="relative bg-neutral max-w-[100dvw] font-montserrat w-dvw min-h-dvh">
		<!-- Header -->
		<header
			v-if="!isJoinUsPage"
			:class="{
				'fixed top-0 left-0 right-0': true /*isHomePage*/,
				// 'relative': !isHomePage,
				'shadow-lg': (!shouldNavBeFixed || !isHomePage) && !isNavMenuOpen,
				'bg-opacity-0': shouldNavBeFixed && isHomePage,
				'bg-opacity-90': !shouldNavBeFixed,
				'!bg-opacity-100': isNavMenuOpen
			}"
			class="bg-neutral z-30 h-[69px] transition-background-color duration-300 ease-in-out"
		>
			<div class="container mx-auto p-4 flex justify-between md:justify-around items-center">
				<!-- Hamburger Icon for Mobile -->
				<div class="flex flex-row space-x-2">
					<div class="md:hidden h-8">
						<button @click="toggleNavMenu"
					        ref="navMenuToggle"
					        aria-label="Toggle Navigation Menu"
					        class="focus:outline-none h-8"
					        :class="{
								'text-black': isNavMenuOpen,
								'text-white': shouldNavBeFixed && isHomePage && !isNavMenuOpen,
							}"
						>
							<svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path v-if="isNavMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"></path>
								<path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"></path>
							</svg>
						</button>
					</div>

					<NuxtLink class="h-8" to="/" aria-label="home page">
						<NuxtImg
							:class="{
								'brightness-0 saturate-100 invert': shouldNavBeFixed && isHomePage && !isNavMenuOpen,
							}"
							alt="Evonap logo"
							class="h-8 hover:[filter:brightness(0)_saturate(100%)_invert(86%)_sepia(75%)_saturate(6182%)_hue-rotate(317deg)_brightness(101%)_contrast(101%)]"
							src="/logo.svg" width="96" height="24" />
					</NuxtLink>
				</div>



				<!-- Desktop Navigation -->
				<nav class="hidden md:flex space-x-6"
				     :class="{
						'text-neutral': shouldNavBeFixed && isHomePage,
					    'text-black': !isHomePage
					}"
				>
					<ul class="relative flex space-x-6 h-10">
						<li class="flex items-center text-lg" id="product-popover">
							<a href="#" ref="productDropdownBtn" @click="showProducts = !showProducts" class="hover:text-primary">Products</a>
							<div ref="productDropdown" v-show="showProducts || showProductsHover || showProductsHover2" v-auto-animate
							     class="absolute z-10 bg-white top-10 -left-10 rounded-xl right-auto text-start text-sm font-medium
							      mx-auto p-2 flex flex-col justify-center shadow shadow-stone-700">
								<a
									v-for="el in [
										{ name: 'Standard Pod', slug: '/product/standard', image: '/images/home_header_1_1920x794.jpg'},
										{ name: 'Deluxe Pod', slug: '/product/deluxe', image: '/images/home_header_1_1920x794.jpg'},
										{ name: 'Compact Pod', slug: '/product/compact', image: '/images/home_header_1_1920x794.jpg', first: true},
										{ name: 'Custom Pod', slug: '/product/custom', image: '/images/home_header_1_1920x794.jpg'},
									]"
									:href="el.slug"
									class="relative flex justify-center w-36 cursor-pointer hover:bg-gray-200 hover:rounded-md">
									<span class="w-full text-center my-3 mx-4 text-black">{{ el.name }}</span>
								</a>
							</div>
						</li>
						<li class="flex items-center text-lg">
							<NuxtLink href="/blogs" class="hover:text-primary">Blogs</NuxtLink>
						</li>
						<li class="flex items-center text-lg">
							<NuxtLink href="/about-us" class="hover:text-primary">About Us</NuxtLink>
						</li>
					</ul>
				</nav>

				<!-- Join Us Button -->
				<NuxtLink
					to="/join-us"
					class="inline text-center bg-primary text-black hover:bg-[#ffdb84] h-10 w-24 p-2 rounded-lg font-medium"
				>
					Join Us
				</NuxtLink>
			</div>
		</header>

		<!-- Background Overlay -->
		<div
			v-show="isNavMenuOpen"
			class="md:collapse absolute top-[69px] left-0 right-0 bottom-0 inset-0 z-10 transition-opacity duration-300 ease-in-out bg-black bg-opacity-50"
		></div>

		<!-- Offscreen Mobile Menu (Canvas) -->
		<div
			ref="mobileNav"
			:class="{
				'-translate-y-full z-0': !isNavMenuOpen,
				'translate-y-0 z-20': isNavMenuOpen,
			}"
			class="fixed top-[69px] left-0 right-0 w-full h-auto bg-[#eaeaec] text-black transition ease-in-out duration-700 transform md:hidden"
		>
			<!-- Mobile Navigation Links -->
			<ul class="space-y-0.5 transition-colors duration-500 ease-in-out"
			    :class="{
					'text-transparent': !isNavMenuOpen,
					'text-black': isNavMenuOpen,
				}"
			>
				<li class="bg-white">
					<NuxtLink href="/" @click="isNavMenuOpen = false" class="block py-4 px-8">Home</NuxtLink>
				</li>
				<li class="bg-white">
					<NuxtLink href="/join-us" @click="isNavMenuOpen = false" class="block py-4 px-8">Join Us</NuxtLink>
				</li>
				<li class="relative bg-white">
					<p @click="toggleMobileProductDropdown" class="block py-4 px-8 cursor-pointer">Products</p>
					<svg class="absolute right-8 top-4 transition-transform" height="24" viewBox="0 0 11 6" width="13"
					     :class="{ 'rotate-180': showProductsMobile }"
					>
						<path class="st0" d="M5.4,4.4l4.5-4.2c0.2-0.3,0.7-0.3,0.9,0c0,0,0,0,0,0c0.3,0.3,0.3,0.7,0,1c0,0,0,0,0,0L5.9,5.8 C5.6,6.1,5.2,6.1,5,5.8L0.2,1.1c-0.3-0.3-0.3-0.7,0-0.9C0.4,0,0.8,0,1.1,0.2c0,0,0,0,0,0L5.4,4.4z">
						</path>
					</svg>
				</li>
				<template v-if="showProductsMobile">
					<li class="bg-[#f7f7f7]">
						<NuxtLink href="/product/standard" @click="isNavMenuOpen = false" class="block py-4 px-8">Standard Pod</NuxtLink>
					</li>
					<li class="bg-[#f7f7f7]">
						<NuxtLink href="/product/deluxe" @click="isNavMenuOpen = false" class="block py-4 px-8">Deluxe Pod</NuxtLink>
					</li>
					<li class="bg-[#f7f7f7]">
						<NuxtLink href="/product/compact" @click="isNavMenuOpen = false" class="block py-4 px-8">Compact Pod</NuxtLink>
					</li>
					<li class="bg-[#f7f7f7]">
						<NuxtLink href="/product/custom" @click="isNavMenuOpen = false" class="block py-4 px-8">Custom Pod</NuxtLink>
					</li>
				</template>
				<li class="bg-white">
					<NuxtLink href="/blogs" @click="isNavMenuOpen = false" class="block py-4 px-8">Blogs</NuxtLink>
				</li>
				<li class="bg-white">
					<NuxtLink href="/about-us" @click="isNavMenuOpen = false" class="block py-4 px-8">About Us</NuxtLink>
				</li>

			</ul>
		</div>

		<main>
			<slot/>
		</main>

		<!-- Footer -->
		<footer v-if="!isJoinUsPage" class="bg-secondary text-white pt-6 pb-4">
			<div class="flex flex-col text-center">
				<h3 class="font-black mb-5">Follow us in</h3>
				<div class="flex flex-col sm:flex-row justify-between mb-10 mx-10">
					<div></div>
					<div class="flex flex-row gap-4 flex-wrap">
						<div class="flex flex-row gap-x-4 mx-auto">
							<NuxtLink to="https://www.linkedin.com/company/evonappod" target="_blank" aria-label="Linkedin" class="block rounded-lg">
								<NuxtImg src="/icons/social/linkedin.svg" alt="linkedin" width="40" height="40" class="rounded-lg" />
							</NuxtLink>
<!--							<NuxtLink to="#" target="_blank" aria-label="Facebook" class="block rounded-lg">-->
<!--								<NuxtImg src="/icons/social/facebook.svg" alt="facebook" width="40" height="40" class="rounded-lg" />-->
<!--							</NuxtLink>-->
							<NuxtLink to="https://www.youtube.com/@Evo_Nap" target="_blank" aria-label="Youtube" class="block rounded-lg">
								<NuxtImg src="/icons/social/youtube.svg" alt="youtube" width="40" height="40" class="rounded-lg" />
							</NuxtLink>
							<NuxtLink to="https://www.instagram.com/evo_nap" target="_blank" aria-label="Instagram" class="block rounded-lg">
								<NuxtImg src="/icons/social/instagram.svg" alt="instagram" width="40" height="40" class="rounded-lg" />
							</NuxtLink>
							<NuxtLink to="https://t.me/EvoNap" target="_blank" aria-label="Telegram" class="block rounded-lg">
								<NuxtImg src="/icons/social/telegram.svg" alt="telegram" width="40" height="40" class="rounded-lg" />
							</NuxtLink>
						</div>
						<span class="invisible xsm:visible bg-gray-400 w-[1px] mx-3 xsm:basis-auto basis-full"></span>
						<NuxtLink to="mailto:info@evonap.com" class="block m-auto">
							info@evonap.com
						</NuxtLink>
					</div>
					<div class="mt-8 sm:mt-0">
						<NuxtImg class="brightness-0 saturate-100 invert mx-auto" src="/logo.svg" alt="logo" width="96" height="40" />
					</div>
				</div>
				<span class="bg-gray-900 w-full m-auto h-[1px] mb-4"></span>
				<div class="flex flex-row justify-around">
					<p class="font-normal text-xs md:text-base">&copy; 2024 EvoNap. All Rights Reserved.</p>
<!--					<div class="flex flex-row gap-2">-->
<!--						<NuxtLink to="#" class="block m-auto">-->
<!--							Terms and Conditions-->
<!--						</NuxtLink>-->
<!--						<span class="bg-gray-400 w-[1px]"></span>-->
<!--						<NuxtLink to="#" class="block m-auto">-->
<!--							Privacy Policy-->
<!--						</NuxtLink>-->
<!--					</div>-->
				</div>
			</div>
		</footer>
	</div>
</template>

<style scoped>
/*.mobileProductDropdown-move,
.mobileProductDropdown-enter-active,
.mobileProductDropdown-leave-active {
	@apply transition-opacity duration-1000 ease-in-out transform-gpu
}
.mobileProductDropdown-enter-from,
.mobileProductDropdown-leave-to {
	@apply scale-y-0
}*/

</style>
